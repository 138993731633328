import * as React from "react";
import * as ReactDOM from "react-dom";
import {MainFrame} from "./components/MainFrame";
import {CacheLoader} from "./helpers/CacheLoader";
import './index.css';

CacheLoader.start();

ReactDOM.render(
    <MainFrame />,
    document.getElementById('root')
);
import {BioMetricMeasure, BioMetricType} from "../model/BioMetric";
import {dayEpoch, dayUtcEpoch, epochToDayEpoch, percentLabeler, TypeLabeler, weightLabeler} from "./Util";
import {desiredValueAt, GoalEntity, GoalSet} from "../model/Goal";

export enum TypeChangeQualifier{
    NONE,
    GAINED,
    LOST
}

export interface TypeSummary{
    today: number,
    last: number,
    diff: number,
    good: boolean,
    qualifier: TypeChangeQualifier,
    readableChange: string,
}

export function qualifierString(t: TypeChangeQualifier){
    switch (t){
        case TypeChangeQualifier.NONE: return '';
        case TypeChangeQualifier.GAINED: return 'Gained';
        case TypeChangeQualifier.LOST: return 'Lost';
    }
    return '';
}

export class BioMetricAnalytics{

    readonly data: BioMetricMeasure[];

    constructor(
        readonly goalSet: GoalSet,
        data: BioMetricMeasure[]
    ){
        this.data = data.sort( (a, b) => a.date - b.date );
    }

    private getToday(): number{
        return dayEpoch(new Date(dayUtcEpoch(new Date()) * 1000));
    }

    typeData(type: BioMetricType): BioMetricMeasure[]{
        return this.data.filter(m => m.type === type);
    }

    measureOfDayIndex(type: BioMetricType, day: number): number{
        return this.typeData(type).findIndex(m => epochToDayEpoch(m.date) === day);
    }

    measureOfDay(type: BioMetricType, day?: number): number | undefined{

        if (typeof day === "undefined"){
            day = this.getToday();
        }

        const index = this.measureOfDayIndex(type, day);

        return index >= 0 ? this.typeData(type)[index].value : undefined;
    }

    measureOfToday(type: BioMetricType): number | undefined{
        return this.measureOfDay(type, this.getToday());
    }

    measureOfBeforeToday(type: BioMetricType): number | undefined{
        const index = this.measureOfDayIndex(type, this.getToday());

        if (index > 0){
            return this.typeData(type)[index - 1].value;
        }
    }

    goalByType(type: BioMetricType): GoalEntity | undefined{
        return this.goalSet.goals.find(g => g.type === type);
    }

    desired(type: BioMetricType, epoch?: number): number | undefined{
        const g = this.goalByType(type);

        if(!g){
            return undefined;
        }

        if(typeof epoch === "undefined"){
            epoch = this.getToday();
        }

        return desiredValueAt(this.goalSet.startDate, this.goalSet.endDate, g.startValue, g.endValue, epoch);
    }

    getTypeDiffGood(type: BioMetricType, diff: number): boolean{
        return type === BioMetricType.MUSCLE_MASS_KG ? diff < 0 : diff > 0
    }

    getTypeLabeler(type: BioMetricType): TypeLabeler{
        if (type === BioMetricType.FAT_RATIO){
            return percentLabeler;
        }else{
            return weightLabeler;
        }
    }

    typeStats(type:BioMetricType, epoch?: number): [number, number, number, boolean]{
        const desired = this.desired(type, epoch) ?? 0;
        const current = this.measureOfDay(type, epoch) ?? 0;
        const offBy = desired - current;
        const good = this.getTypeDiffGood(type, offBy);
        return [desired, current, offBy, good];
    }

    typeSummary(type:BioMetricType): TypeSummary | undefined{
        const today = this.measureOfToday(type);

        if(typeof today === 'undefined'){
            return undefined;
        }

        const last = this.measureOfBeforeToday(type) || 0;
        const diff = last - today;
        const good = this.getTypeDiffGood(type, diff);

        let qualifier: TypeChangeQualifier = TypeChangeQualifier.NONE;

        if (diff > 0){
            qualifier = TypeChangeQualifier.LOST;
        }else if (diff < 0){
            qualifier = TypeChangeQualifier.GAINED;
        }

        const sign = qualifier === TypeChangeQualifier.LOST ? '-' : '+';
        const labeler = this.getTypeLabeler(type);
        const readableChange = `${sign}${labeler(Math.abs(diff))}`;

        return {
            today, last, good, qualifier, diff, readableChange
        };

    }

    printReport(){

        const report = (type: BioMetricType, title: string) => {
            console.log(title);
            for(const d of this.typeData(type)){
                console.log(new Date(d.date * 1000), d.value)
            }
        };

        report(BioMetricType.WEIGHT_KG, 'WEIGHT_KG');
        report(BioMetricType.FAT_RATIO, 'FAT_RATIO');
        report(BioMetricType.MUSCLE_MASS_KG, 'MUSCLE_MASS_KG');
        report(BioMetricType.HYDRATION_KG, 'HYDRATION_KG');

    }

}
import * as React from "react";
import {GoalGraph} from "./GoalGraph";
import {
    DAY_FACTOR,
    dayEpoch,
    dayLabeler,
    dayUtcEpoch,
    kgLabeler,
    percentLabeler,
    weightLabeler
} from "../helpers/Util";
import {desiredValueAt, GoalEntity, GoalRepo, GoalSet} from "../model/Goal";
import './WeightGraphsView.css'
import {BioMetricAnalytics, qualifierString} from "../helpers/BioMetricAnalytics";
import {BioMetricMeasure, BioMetricRepo, BioMetricType} from "../model/BioMetric";
import {CacheLoader} from "../helpers/CacheLoader";
import {makeSz} from "../helpers/Rectangle";

export interface WeightGraphsViewProps{
    uname: string;
}

export interface WeightGraphsViewState{
    goalSet?: GoalSet;
    analytics?: BioMetricAnalytics;
}

enum GraphType{
    MAIN,
    HALF,
    THIRD,
}

const LOAD_RETRY_TIME = 500;

const Palette = {
    weight: '#A00DEF',
    muscle: '#BE156E',
    fat: '#FCCE5E',
    water: '#06A6BE',
}

export class WeightGraphsView extends React.Component<WeightGraphsViewProps, WeightGraphsViewState>{

    constructor(props: WeightGraphsViewProps){
        super(props);

        this.state = {};
    }

    private loadDate() {

        const doTry = () => {

            if (CacheLoader.goals.isLoading){
                console.log('Waiting for load...')
                setTimeout(() => doTry(), LOAD_RETRY_TIME);
            }

            const entry = CacheLoader.goals.byUser(this.props.uname);

            if (entry){
                const goalSet = entry.goalSet;
                const analytics = new BioMetricAnalytics(goalSet, entry.measures);

                this.setState({analytics, goalSet});
            }
        };

        doTry();

    }

    componentDidMount() {
        this.loadDate();
    }

    componentDidUpdate(prevProps: Readonly<WeightGraphsViewProps>, prevState: Readonly<WeightGraphsViewState>, snapshot?: any) {
        if (prevProps.uname != this.props.uname){
            this.setState({analytics: undefined, goalSet: undefined})
            this.loadDate();
        }
    }

    createBadgeElement(): React.ReactNode{

        const uname = this.props.uname;
        const analytics = this.state.analytics;

        if(!analytics){
            return <></>;
        }

        const weight = analytics.typeSummary(BioMetricType.WEIGHT_KG);
        const muscle = analytics.typeSummary(BioMetricType.MUSCLE_MASS_KG);
        const fat    = analytics.typeSummary(BioMetricType.FAT_RATIO);
        const water  = analytics.typeSummary(BioMetricType.HYDRATION_KG);
        const sign = (b?: boolean) => b === true ? 'positive' : (b === false ? 'negative' : '');

        return (
            <>
                <div className="profile-pic">
                    <img src={`res/${uname}.png`} alt={uname}/>
                </div>
                <div className="metrics">
                    <div className="weight-row head-metric">
                        {weight ?
                        <>
                            <span className="caption">{qualifierString(weight.qualifier)}</span>&nbsp;
                            <span className={`value ${sign(weight.good)}`}>{weightLabeler(weight.diff)}</span>
                        </> :
                            <span className="caption">Not measured</span>
                        }
                    </div>
                    <div className="secondary-row">
                        <div className={`head-metric muscle ${sign(muscle?.good)}`}>
                            <div className="value">{muscle?.readableChange}</div>
                            <div className="title">Muscle</div>
                        </div>
                        <div className={`head-metric fat ${sign(fat?.good)}`}>
                            <div className="value">{fat?.readableChange}</div>
                            <div className="title">Fat</div>
                        </div>
                        <div className={`head-metric water ${sign(water?.good)}`}>
                            <div className="value">{water?.readableChange}</div>
                            <div className="title">Water</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    createMidTableElement(): React.ReactNode{

        const [desiredWei, currentWei, offWei, goodWei] = this.state.analytics!.typeStats(BioMetricType.WEIGHT_KG);
        const [desiredFat, currentFat, offFat, goodFat] = this.state.analytics!.typeStats(BioMetricType.FAT_RATIO);
        const [desiredMus, currentMus, offMus, goodMus] = this.state.analytics!.typeStats(BioMetricType.MUSCLE_MASS_KG);
        const [desiredWat, currentWat, offWat, goodWat] = this.state.analytics!.typeStats(BioMetricType.HYDRATION_KG);

        const sign = (good: boolean) => good ? 'positive' : 'negative';

        return (
            <div className="summary-table">
                <div className="row headers">
                    <div className="cell"/>
                    <div className="cell head">Desired</div>
                    <div className="cell head">Current</div>
                    <div className="cell head">Off By</div>
                </div>
                <div className="sub-table">
                    <div className="row">
                        <div className="cell head">Weight</div>
                        <div className="cell">{kgLabeler(desiredWei)}</div>
                        <div className={`cell ${sign(goodWei)}`}>{kgLabeler(currentWei)}</div>
                        <div className={`cell ${sign(goodWei)}`}>{weightLabeler(offWei)}</div>
                    </div>
                    <div className="row">
                        <div className="cell head">Fat</div>
                        <div className="cell fat-color">{percentLabeler(desiredFat)}</div>
                        <div className={`cell ${sign(goodFat)}`}>{percentLabeler(currentFat)}</div>
                        <div className={`cell ${sign(goodFat)}`}>{percentLabeler(offFat)}</div>
                    </div>
                    <div className="row">
                        <div className="cell head">Muscle</div>
                        <div className="cell muscle-color">{kgLabeler(desiredMus)}</div>
                        <div className={`cell ${sign(goodMus)}`}>{kgLabeler(currentMus)}</div>
                        <div className={`cell ${sign(goodMus)}`}>{weightLabeler(offMus)}</div>
                    </div>
                    <div className="row">
                        <div className="cell head">Water</div>
                        <div className="cell water-color">{kgLabeler(desiredWat)}</div>
                        <div className={`cell ${sign(goodWat)}`}>{kgLabeler(currentWat)}</div>
                        <div className={`cell ${sign(goodWat)}`}>{weightLabeler(offWat)}</div>
                    </div>
                </div>
            </div>
        );
    }

    createGraph(
        type: GraphType,
        startDate: number,
        endDate: number,
        startValue: number,
        endValue: number,
        entries: BioMetricMeasure[],
        color?: string,
        label?: string,
    ): React.ReactNode{

        const scrW = window.innerWidth, scrH = window.innerHeight;
        const leftPad = 70;
        const rightPad = 20;
        const intraPad = 20;
        const hPad = leftPad + rightPad;
        const widthCalc = (amount: number) => (scrW - hPad - intraPad * (amount - 1)) / amount;

        const sel = function<T>(a: T, b: T, c: T): T{
            if (type == GraphType.MAIN) return a;
            if (type == GraphType.HALF) return b;
            return c;
        }

        return (
            <div className="graph">
                <GoalGraph
                    width={sel(scrW - rightPad, widthCalc(2), widthCalc(3))}
                    height={sel(300, 200, 164)}
                    startDate={startDate}
                    endDate={endDate}
                    startValue={startValue}
                    endValue={endValue}
                    xSegments={sel(7, 7, 7)}
                    ySegments={sel(5, 0, 0)}
                    xLabeler={dayLabeler}
                    yLabeler={kgLabeler}
                    entries={entries}
                    progress={dayEpoch(new Date())}
                    lineWidth={sel(5, 2, 2)}
                    hideVerticalLabels={sel(false, true, true)}
                    hideHorizontalLabels={sel(false, true, true)}
                    hideDataMarkers={sel(false, true, true)}
                    dataColor={color}
                />
                {label ? <div className="label">{label}</div> : undefined}
            </div>

        );
    }

    createGraphsElement(): React.ReactNode{

        const {analytics, goalSet} = this.state;

        if (!goalSet || !analytics){
            return <></>;
        }

        const today = dayUtcEpoch();
        const sevenAgo = today - 7 * DAY_FACTOR;
        const weightGoal = goalSet.goals[0];
        const fatGoal = goalSet.goals[1];
        const muscleGoal = goalSet.goals[2];
        const waterGoal = goalSet.goals[3];
        const startValue = (g: GoalEntity) => desiredValueAt(goalSet.startDate, goalSet.endDate, g.startValue, g.endValue, sevenAgo);
        const endValue = (g: GoalEntity) => desiredValueAt(goalSet.startDate, goalSet.endDate, g.startValue, g.endValue, today);

        return (
            <div>
                <h2>7 Days</h2>
                {this.createGraph(GraphType.MAIN, sevenAgo, today, startValue(weightGoal), endValue(weightGoal),
                    analytics.typeData(BioMetricType.WEIGHT_KG).filter(m => m.date >= sevenAgo))}

                <div className="graph-bar">
                    {this.createGraph(GraphType.THIRD, sevenAgo, today, startValue(muscleGoal), endValue(muscleGoal),
                        analytics.typeData(BioMetricType.MUSCLE_MASS_KG).filter(m => m.date >= sevenAgo),
                        Palette.muscle, 'Muscle')}

                    {this.createGraph(GraphType.THIRD, sevenAgo, today, startValue(fatGoal), endValue(fatGoal),
                        analytics.typeData(BioMetricType.FAT_RATIO).filter(m => m.date >= sevenAgo),
                        Palette.fat, 'Fat')}

                    {this.createGraph(GraphType.THIRD, sevenAgo, today, startValue(waterGoal), endValue(waterGoal),
                        analytics.typeData(BioMetricType.HYDRATION_KG).filter(m => m.date >= sevenAgo),
                        Palette.water, 'Water')}

                </div>
                <div className="summary">
                    {this.createMidTableElement()}
                </div>
                <h2>All Time</h2>
                <div className="graph-bar">

                    {this.createGraph(GraphType.HALF, goalSet.startDate, goalSet.endDate,
                        weightGoal.startValue, weightGoal.endValue, analytics.typeData(BioMetricType.WEIGHT_KG),
                    undefined, 'Weight')}

                    {this.createGraph(GraphType.HALF, goalSet.startDate, goalSet.endDate,
                        fatGoal.startValue, fatGoal.endValue, analytics.typeData(BioMetricType.FAT_RATIO),
                        Palette.fat, 'Fat')}

                </div>
                <div className="graph-bar">

                    {this.createGraph(GraphType.HALF, goalSet.startDate, goalSet.endDate,
                        muscleGoal.startValue, muscleGoal.endValue, analytics.typeData(BioMetricType.MUSCLE_MASS_KG),
                        Palette.muscle, 'Muscle')}

                    {this.createGraph(GraphType.HALF, goalSet.startDate, goalSet.endDate,
                        waterGoal.startValue, waterGoal.endValue, analytics.typeData(BioMetricType.HYDRATION_KG),
                        Palette.water, 'Water')}

                </div>
            </div>
        );
    }

    render(){
        return (
            <div className="weight-graphs">
                <div className="profile-side">
                    {this.createBadgeElement()}
                </div>
                <div className="graphs-side">
                    {this.createGraphsElement()}
                </div>
            </div>
        );

    }
}


export const DAY_FACTOR = 24 * 60 * 60;

export type TypeLabeler = (n: number) => string;

export function _undef(what: any):  boolean{
    return "undefined" === typeof what;
}

export function dayUtcEpoch(date: Date | null = null, daysOffset: number = 0): number{
    if(!date) date = new Date();
    return Math.round( Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) / 1000 + daysOffset * DAY_FACTOR);
}
export function dayEpoch(date: Date | null = null, daysOffset: number = 0): number{
    if(!date) date = new Date();
    return Math.round( new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() / 1000 + daysOffset * DAY_FACTOR);
}

export function fromDayEpoch(dayEpoch: number): Date{
    return new Date(dayEpoch * 1000);
}

export function epochToDayEpoch(epoch: number): number{
    return dayEpoch(new Date(epoch * 1000));
}

export function getWeek(source: Date) {
    let date = new Date(source.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    let week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}

export function weightLabeler(w: number): string{

    w = Math.abs(w);

    if(w > 1){
        return `${w.toFixed(1)}kg`;
    }else{
        return `${Math.round(w * 1000)}g`;
    }
}

export function weekLabeler(epoch: number): string{
    return 'W' + getWeek(new Date(epoch * 1000))
}

export function dayLabeler(epoch: number): string{
    return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][new Date(epoch * 1000).getDay()];
}

export function kgLabeler(value: number): string{
    return value.toFixed(1).toString() + 'kg';
}

export function percentLabeler(value: number): string{
    return value.toFixed(1).toString() + '%';
}

export function debug(what: string){
    if (document.location.host.indexOf('localhost') >= 0){
        console.log(what);
    }
}
export interface User{
    uname: string;
}

export class UserRepo{

    static async getAll(): Promise<User[]>{
        return [
            {uname: "Mariola"},
            {uname: "Jose"}
        ];
    }

}

/**
 * Last sample had:
 * 4 - Height (meter)
 * 1 - Weight (kg)
 * 8 - Fat Mass Weight (kg)
 * 6 - Fat Ratio (%)
 * 5 - Fat Free Mass (kg)
 * 76 - Muscle Mass (kg)
 * 77 - Hydration (kg)
 * 88 - Bone Mass (kg)
 */
export enum BioMetricType{
    UNKNOWN,
    WEIGHT_KG,
    HEIGHT_M,
    FAT_MASS_KG,
    FAT_RATIO,
    FAT_FREE_MASS_KG,
    MUSCLE_MASS_KG,
    HYDRATION_KG,
    BONE_MASS_KG,
}

export interface BioMetricMeasure{
    type: BioMetricType;
    date: number; // Epoch Seconds
    value: number;
}

function dataUrl(uname: string, startDate: number, endDate: number): string{
    return `https://withings-scraper.jose.page/users/${uname}/measures?start=${startDate}&end=${endDate}`
}

interface WithingsMeasure {
    day: number;
    type: number;
    value: number
}

export const WithingsTypes: any = {
    "1"	    :"Weight (kg)",
    "4"	    :"Height (meter)",
    "5"	    :"Fat Free Mass (kg)",
    "6"	    :"Fat Ratio (%)",
    "8"	    :"Fat Mass Weight (kg)",
    "9"	    :"Diastolic Blood Pressure (mmHg)",
    "10"	:"Systolic Blood Pressure (mmHg)",
    "11"	:"Heart Pulse (bpm) - only for BPM and scale devices",
    "12"	:"Temperature (celsius)",
    "54"	:"SP02 (%)",
    "71"	:"Body Temperature (celsius)",
    "73"	:"Skin Temperature (celsius)",
    "76"	:"Muscle Mass (kg)",
    "77"	:"Hydration (kg)",
    "88"	:"Bone Mass (kg)",
    "91"	:"Pulse Wave Velocity (m/s)",
    "123"	:"VO2 max is a numerical measurement of your body’s ability to consume oxygen (ml/min/kg).",
    "135"	:"QRS interval duration based on ECG signal",
    "136"	:"PR interval duration based on ECG signal",
    "137"	:"QT interval duration based on ECG signal",
    "138"	:"Corrected QT interval duration based on ECG signal",
    "139"	:"Atrial fibrillation result from PPG",
};

export class BioMetricRepo{

    static async byUser(uname: string, start: number, end: number): Promise<BioMetricMeasure[]>{

        /**
         * Last sample had:
         * 1 - Weight (kg)
         * 4 - Height (meter)
         * 5 - Fat Free Mass (kg)
         * 6 - Fat Ratio (%)
         * 8 - Fat Mass Weight (kg)
         * 76 - Muscle Mass (kg)
         * 77 - Hydration (kg)
         * 88 - Bone Mass (kg)
         */
        const result: BioMetricMeasure[] = [];
        const remoteResponse = await fetch(dataUrl(uname, start, end));
        const json = await remoteResponse.json();

        if( json.data instanceof Array){
            for(const m of json.data as WithingsMeasure[]){

                let type: BioMetricType;

                switch (m.type) {
                    case 1: type = BioMetricType.WEIGHT_KG; break;
                    case 4: type = BioMetricType.HEIGHT_M; break;
                    case 5: type = BioMetricType.FAT_FREE_MASS_KG; break;
                    case 6: type = BioMetricType.FAT_RATIO; break;
                    case 8: type = BioMetricType.FAT_MASS_KG; break;
                    case 76: type = BioMetricType.MUSCLE_MASS_KG; break;
                    case 77: type = BioMetricType.HYDRATION_KG; break;
                    default: type = BioMetricType.UNKNOWN; break;
                }

                result.push({type, date: m.day, value: m.value})

            }
        }

        return result;

    }

}

import {dayUtcEpoch} from "../helpers/Util";
import {BioMetricType} from "./BioMetric";
import {makePt, Point} from "../helpers/Rectangle";

export interface GoalSet{
    startDate: number;
    endDate: number;
    goals: GoalEntity[];
}

export interface GoalEntity {
    name: string;
    startValue: number;
    endValue: number;
    type: BioMetricType;
}

export function getLineY(p1: Point, p2: Point): (x: number) => number {
    const x1 = p1.x,
          y1 = p1.y,
          x2 = p2.x,
          y2 = p2.y;

    const m = (y2 - y1) / (x2 - x1);
    const b = y1 - m * x1;

    return x => m * x + b;
}

export function desiredValueF(startDate: number, endDate: number, startValue: number, endValue: number): (x: number) => number{
    return getLineY(makePt(startDate, startValue), makePt(endDate, endValue));
}

export function desiredValueAt(startDate: number, endDate: number, startValue: number, endValue: number, epoch: number): number{
    return desiredValueF(startDate, endDate, startValue, endValue)(epoch);
}

export class GoalRepo {

    static async byUser(uname: string): Promise<GoalSet[]>{
        switch (uname) {
        case "Jose":
            return [{
                startDate: dayUtcEpoch(new Date(2022, 0, 2)),
                endDate: dayUtcEpoch(new Date(2022, 5, 31)),
                goals: [
                    {
                        name: "Weight (Kg)",
                        startValue: 112,
                        endValue: 90,
                        type: BioMetricType.WEIGHT_KG,
                    },
                    {
                        name: "Fat (%)",
                        startValue: 25,
                        endValue: 15,
                        type: BioMetricType.FAT_RATIO
                    },
                    {
                        name: "Muscle (Kg)",
                        startValue: 78,
                        endValue: 77.4,
                        type: BioMetricType.MUSCLE_MASS_KG
                    },
                    {
                        name: "Water (Kg)",
                        startValue: 55,
                        endValue: 45,
                        type: BioMetricType.HYDRATION_KG
                    }
                ],

            }];
        case "Mariola":
            return [{
                startDate: dayUtcEpoch(new Date(2022, 0, 2)),
                endDate: dayUtcEpoch(new Date(2022, 5, 31)),
                goals: [
                    {
                        name: "Weight (Kg)",
                        startValue: 68,
                        endValue: 53,
                        type: BioMetricType.WEIGHT_KG,
                    },
                    {
                        name: "Fat (%)",
                        startValue: 38,
                        endValue: 21,
                        type: BioMetricType.FAT_RATIO
                    },
                    {
                        name: "Muscle (Kg)",
                        startValue: 38.9,
                        endValue: 17.49,
                        type: BioMetricType.MUSCLE_MASS_KG
                    },
                    {
                        name: "Water (Kg)",
                        startValue: 28,
                        endValue: 24,
                        type: BioMetricType.HYDRATION_KG
                    }
                ],
            }];
        default:
            throw new Error("Unknown user");
        }
    }

}
import {GoalsCache} from "../model/GoalsCache";
import {debug} from "./Util";

const REFRESH_TIME = 15 * 1000;

export class CacheLoader{

    static goals = new GoalsCache();

    private static refresh(){
        if(!this.goals.isLoading){
            this.goals.invalidate()
                .then(() => {
                    //debug(`Updated ${new Date()}`);
                    setTimeout( ()=> CacheLoader.refresh(), REFRESH_TIME );
                });
        }
    }

    static start(){
        this.refresh();
    }

}
import * as React from "react";
import {WeightGraphsView} from "./WeightGraphsView";
import {CoverView} from "./CoverView";
import {_undef, debug} from "../helpers/Util";
import './MainFrame.css';

const INTERVAL = 10000;

export interface MainFrameProps{

}

export interface MainFrameState{
    currentScreen: number,
}

export class MainFrame extends React.Component<MainFrameProps, MainFrameState>{

    screens: React.ReactNode[] = [];

    constructor(props: MainFrameProps) {
        super(props);

        this.state = {currentScreen: 0};

        this.screens = [
            <CoverView />,
            <WeightGraphsView uname={`Mariola`}/>,
            <WeightGraphsView uname={`Jose`}/>,
        ]
    }

    componentDidMount() {
        if(_undef(this.lock)){
            setInterval(() => {

                let currentScreen = this.state.currentScreen + 1;

                if (currentScreen >= this.screens.length){
                    currentScreen = 0;
                }

                this.setState({currentScreen});

            }, INTERVAL);
        }

    }

    render(){

        debug(`Selecting index: ${this.hasLock ? this.lock! : this.state.currentScreen}`)

        return (
            <div className="main-frame">
                {this.screens[this.hasLock ? this.lock! : this.state.currentScreen]}
            </div>
        );

    }

    get hasLock(): boolean{
        return !_undef(this.lock);
    }

    get lock(): number | undefined{
        const p = new URLSearchParams(window.location.search);
        const lock = p.get('lock');

        if (lock == null){
            return undefined;
        }
        return  parseInt(lock!);
    }

}
import * as React from "react";
import {RefObject} from "react";
import {expPt, makePt, makeSz, Point, Rectangle} from "../helpers/Rectangle";
import {desiredValueAt} from "../model/Goal";
import './GoalGraph.css'
import {BioMetricMeasure} from "../model/BioMetric";

const axisSize = makeSz(70, 40);
const DEFAULT_LINE_WIDTH = 2;

const palette = {
    data : '#A00DEF',
    goal: '#4508E9',
    progress: '#FF9315',
    axis: '#22202B',
    axisBold: '#22202B',
    labels: '#736E86',
}

type SeriesPoint = Point | null;

export interface GoalGraphProps{
    width: number;
    height: number;
    startDate: number;
    endDate: number;
    startValue: number;
    endValue: number;
    entries: BioMetricMeasure[];
    xSegments: number;
    ySegments: number;
    xLabeler: (value: number) => string;
    yLabeler: (value: number) => string;
    progress: number;
    hideHorizontalLabels?: boolean;
    hideVerticalLabels?: boolean
    drawVerticalLabels?: boolean;
    drawHorizontalLabels?: boolean;
    hideDataMarkers?: boolean;
    lineWidth?: number;
    dataColor?: string;
}

export interface GoalGraphState{

}

interface DataSetMeta{
    minDate: number;
    maxDate: number;
    minValue: number;
    maxValue: number;
    values: number[];
    xStops: number[];
    yStops: number[];
    desiredValue: number;
    currentValue: number;
    offByValue: number;
    currentValueSign?: string;
    offByValueSign?: string;
    xAxisArea: Rectangle;
    yAxisArea: Rectangle;
    dataArea: Rectangle;
}

function date2XCoord(meta: DataSetMeta, area: Rectangle, date: number): number{
    const {minDate, maxDate} = meta;
    const d = date, d0 = minDate, d1 = maxDate, l = area.left, w = area.width;
    return (d - d0) * w / (d1 - d0) + l;

}

function value2YCoord(meta: DataSetMeta, area: Rectangle, value: number): number{
    const {minValue, maxValue} = meta;
    const v = value, v0 = minValue, v1 = maxValue, t = area.top, h = area.height;
    return (v - v0) * h / (v1 - v0) + t;
}

function data2Coord(meta: DataSetMeta, area: Rectangle, value: number, date: number): Point{
    return makePt(date2XCoord(meta, area, date), value2YCoord(meta, area, value));
}

function drawLine(cx: CanvasRenderingContext2D, points: (Point | null)[]){

    cx.beginPath();

    let started = false;

    for(const p of points){
        if (!p) continue;

        if (started){
            cx.moveTo(...expPt(p));
            started = false;
        }

        cx.lineTo(...expPt(p));
    }

    cx.stroke();
}

function createSegments(start: number, end: number, segments: number): number[]{
    const r: number[] = [start];
    const delta = (end - start) / segments;

    for(let i = 2; i <= segments; i++){
        r.push(start + delta * (i - 1));
    }

    r.push(end);

    return r;
}

function roundRect(
    ctx: CanvasRenderingContext2D,
    r: Rectangle,
    radius: number | {tl: number, tr: number, br: number, bl: number} = 10
) {

    const [x, y, width, height] = r.tuple;

    if (typeof radius === 'number') {
        radius = {tl: radius, tr: radius, br: radius, bl: radius};
    }

    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();

}

function enableShadows(cx: CanvasRenderingContext2D){

    cx.shadowOffsetX = 0;
    cx.shadowOffsetY = 3;
    cx.shadowBlur = 15;

}

function disableShadows(cx: CanvasRenderingContext2D){

    cx.shadowOffsetX = 0;
    cx.shadowOffsetY = 0;
    cx.shadowBlur = 0;
    cx.shadowColor = `rgba(0, 0 ,0, 0)`;

}

export class GoalGraph extends React.Component<GoalGraphProps, GoalGraphState>{

    readonly canvasRef: RefObject<HTMLCanvasElement> = React.createRef();

    readonly meta: DataSetMeta = {
        minDate:0, maxDate: 1,
        minValue: 0, maxValue: 1,
        xStops: [0, 1], yStops: [0, 1],
        desiredValue: 0,
        currentValue: 0,
        offByValue: 0,
        values: [],
        xAxisArea: Rectangle.empty,
        yAxisArea: Rectangle.empty,
        dataArea: Rectangle.empty,
    };

    constructor(props: GoalGraphProps) {
        super(props);

        this.state = {
            currentValue: 0,
            offByValue: 0,
            desiredValue: 0,
        }
    }

    private valueAtDate(epoch: number): number{
        return desiredValueAt(this.props.startDate, this.props.endDate, this.props.startValue, this.props.endValue, epoch);
    }

    private drawVertAxisZone(cx: CanvasRenderingContext2D){

        if (this.props.hideVerticalLabels === true || this.props.drawVerticalLabels === false){
            return;
        }

        const area = this.meta.yAxisArea;

        this.meta.yStops.forEach(value => {
            const p = makePt(area.right, value2YCoord(this.meta, area, value));
            const txt = this.props.yLabeler(value);
            const m = cx.measureText(txt);

            cx.font = '15px sans-serif';
            cx.fillStyle = palette.labels;
            cx.fillText(txt, area.left + 5, p.y + 5, area.width);
        });

    }

    private drawHorizAxisZone(cx: CanvasRenderingContext2D){

        if (this.props.drawHorizontalLabels === false || this.props.hideHorizontalLabels === true){
            return false;
        }

        const meta = this.meta;
        const area = this.meta.xAxisArea;
        let count = 0;

        cx.font = '15px sans-serif';
        cx.fillStyle = palette.labels;

        for(const date of meta.xStops){
            const x = date2XCoord(meta, area, date);
            const p = makePt(x, area.top);
            const txt = this.props.xLabeler(date);
            const m = cx.measureText(txt);

            if(count === meta.xStops.length - 1) {
                p.x -= m.width;
            }else if(count > 0){
                p.x -= m.width / 2;
            }

            cx.fillText(txt, p.x, area.top + 25);

            count++;
        }

    }

    private updateMeta(){
        const {xSegments, ySegments, entries, startDate, endDate, startValue, endValue,
            progress, hideHorizontalLabels, hideVerticalLabels} = this.props;
        const values = entries.filter(e => typeof e.value != "undefined").map(e => e.value || 0);
        const meta = this.meta;
        const paddingTop = 10;
        const paddingRight = 10;

        meta.dataArea = Rectangle.fromLTRB(
            hideVerticalLabels === true? 0 : axisSize.width,
            paddingTop,
            this.props.width - paddingRight,
             hideHorizontalLabels === true? this.props.height : this.props.height - axisSize.height);
        meta.xAxisArea = Rectangle.fromLTRB(meta.dataArea.left, meta.dataArea.bottom, meta.dataArea.right, this.props.height);
        meta.yAxisArea = Rectangle.fromLTRB(0, paddingTop, axisSize.width, meta.dataArea.bottom);

        meta.minValue = values.reduce((a, b) => Math.min(a, b), Number.MAX_SAFE_INTEGER);
        meta.maxValue = values.reduce((a, b) => Math.max(a, b), Number.MIN_SAFE_INTEGER);
        meta.minDate = startDate;
        meta.maxDate = endDate;

        meta.minValue = Math.min(meta.minValue, startValue, endValue);
        meta.maxValue = Math.max(meta.maxValue, startValue, endValue);

        meta.xStops = createSegments(meta.minDate, meta.maxDate, xSegments);
        meta.yStops = createSegments(meta.minValue, meta.maxValue, ySegments);

        meta.desiredValue = this.valueAtDate(progress);

        this.props.entries.forEach(entry => {
            if (typeof entry.value !== "undefined"){
                meta.currentValue = entry.value;
            }
        })

        meta.offByValue = meta.desiredValue - meta.currentValue;
        meta.currentValueSign = meta.currentValue < meta.desiredValue ? 'positive' : 'negative';
        meta.offByValueSign = meta.currentValueSign;

    }

    private getDataSeries(): SeriesPoint[]{
        const entries = this.props.entries;
        return entries.map(e => typeof e.value === "undefined" ? null : makePt(e.value, e.date));
    }

    private getGoalSeries(): SeriesPoint[]{
        const entries = this.props.entries.map(e => makePt(this.valueAtDate(e.date), e.date));

        entries.push(makePt(this.valueAtDate(this.meta.maxDate), this.meta.maxDate));
        return entries;
    }

    private getProgressSeries(epoch: number): SeriesPoint[]{
        const entries = this.props.entries;
        return entries.map(e => epoch > e.date ? makePt(this.valueAtDate(e.date), e.date) : null);
    }

    private drawSeries(cx: CanvasRenderingContext2D, area: Rectangle, series: SeriesPoint[], color: string, lineWidth: number = 2){
        cx.strokeStyle = color;
        cx.shadowColor = color;
        cx.lineWidth = lineWidth;
        const pts = series.map(p => p ? data2Coord(this.meta, area, p.x, p.y) : p);
        drawLine(cx, pts);
    }

    private drawSeriesSpots(cx: CanvasRenderingContext2D, area: Rectangle, series: SeriesPoint[], color: string){
        cx.strokeStyle = color;
        cx.shadowColor = palette.data;
        const pts = series.map(p => p ? data2Coord(this.meta, area, p.x, p.y) : p);

        cx.fillStyle = color;
        for(const p of pts){
            if(!p) continue;
            cx.beginPath();
            cx.arc(p.x, p.y, 10, 0, 2 * Math.PI);
            cx.fill();
            cx.strokeStyle = 'black';
            cx.lineWidth = 1;
            cx.stroke();
        }
    }

    private drawGraphDataSeries(cx: CanvasRenderingContext2D, area: Rectangle){
        const series = this.getDataSeries();

        this.drawSeries(cx, area, series, this.props.dataColor ?? palette.data, this.props.lineWidth || DEFAULT_LINE_WIDTH);

        if (this.props.hideDataMarkers !== true){
            this.drawSeriesSpots(cx, area, series, this.props.dataColor ?? palette.data);
        }

    }

    private drawGoalSeries(cx: CanvasRenderingContext2D, area: Rectangle){
        const series = this.getGoalSeries();
        this.drawSeries(cx, area, series, palette.goal, this.props.lineWidth || DEFAULT_LINE_WIDTH);
    }

    private drawProgressSeries(cx: CanvasRenderingContext2D, area: Rectangle, epoch: number){
        const series = this.getProgressSeries(epoch);
        this.drawSeries(cx, area, series, palette.progress, this.props.lineWidth || DEFAULT_LINE_WIDTH);
    }

    private drawAxisGrid(cx: CanvasRenderingContext2D, area: Rectangle){
        const meta = this.meta;
        let even = false;

        cx.strokeStyle = even ? palette.axis : palette.axisBold; even = !even;
        cx.lineWidth = 2;
        roundRect(cx, area);
        cx.stroke();

        for(let i = 0; i < meta.yStops.length; i++){

            if (i === 0 || i === meta.yStops.length - 1) continue;

            const value = meta.yStops[i];
            const y = value2YCoord(meta, area, value);
            const linePoints = [makePt(area.left, y), makePt(area.right, y)];
            drawLine(cx, linePoints);
        }

        for(let i = 0; i < meta.xStops.length; i++){
            if (i === 0 || i === meta.xStops.length - 1) continue;

            const value = meta.xStops[i];
            const x = date2XCoord(meta, area, value);
            const linePoints = [makePt(x, area.top), makePt(x, area.bottom)];
            drawLine(cx, linePoints);
        }

    }

    private drawGraphZone(cx: CanvasRenderingContext2D){

        const area = this.meta.dataArea;
        const progressEpoch = this.props.progress;

        enableShadows(cx);

        this.drawAxisGrid(cx, area);
        this.drawGoalSeries(cx, area);
        this.drawProgressSeries(cx, area, progressEpoch);
        this.drawGraphDataSeries(cx, area);

        disableShadows(cx);
    }

    private drawChart(){
        if(!this.canvasRef.current){
            return;
        }

        const cx = this.canvasRef.current.getContext('2d');

        if(!cx){
            return;
        }

        this.updateMeta();

        cx.clearRect(...Rectangle.fromLTRB(0, 0, this.props.width, this.props.height).tuple);

        this.drawVertAxisZone(cx);
        this.drawHorizAxisZone(cx);
        this.drawGraphZone(cx);
    }

    componentDidMount() {
        this.drawChart();
    }

    componentDidUpdate(prevProps: Readonly<GoalGraphProps>, prevState: Readonly<GoalGraphState>, snapshot?: any) {
        this.drawChart();
    }

    render(){
        this.updateMeta();
        return (
            <div className="goal-graph">
                <canvas
                    ref={this.canvasRef}
                    width={this.props.width}
                    height={this.props.height}/>
            </div>
        );
    }
}
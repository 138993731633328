import * as React from "react";
import './CoverView.css'
import {BioMetricRepo, BioMetricType} from "../model/BioMetric";
import {dayEpoch, dayUtcEpoch} from "../helpers/Util";
import {BioMetricAnalytics} from "../helpers/BioMetricAnalytics";

export interface CoverViewProps{

}

export interface CoverViewState{
    greeting: string;
    greetingSubject: string;
    hours: string;
    minutes: string;
    seconds: string;
    ampm: string;
    date: string;
    status: string;
}

export class CoverView extends React.Component<CoverViewProps, CoverViewState>{

    private intervalSubscription: any;

    constructor(props: CoverViewProps) {
        super(props);

        this.state = {
            greeting: "Good",
            greetingSubject: "You",
            hours: "00",
            minutes: "00",
            seconds: "00",
            ampm: 'a.m.',
            date: String(new Date()),
            status: '',
        };
    }

    update(){

        const greetings = [
            {ends: 3, greet: 'Late\nNight'},
            {ends: 6, greet: 'Early\nMorning'},
            {ends: 12, greet: 'Good\nMorning'},
            {ends: 18, greet: 'Good\nAfternoon'},
            {ends: 25, greet: 'Good\nEvening'},
        ];

        const weekDays = [
            'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
        ];
        const months = "January,February,March,April,May,June,July,August,September,October,November,December".split(',');

        const zero = (n: number) => {
            return (n <= 9 ? '0' : '')  + String(n);
        }

        const d = new Date();
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const seconds = d.getSeconds();
        const day = d.getDate();
        const weekDay = d.getDay();
        const weekDayName = weekDays[weekDay];
        const month = d.getMonth();
        const monthName = months[month];
        const year = d.getFullYear();
        const cardinal = String(day).endsWith('1') ? 'st' : 'th';

        let greeting: string = 'Good';

        for(const greet of greetings){
            if (hours < greet.ends){
                greeting = greet.greet + ',';
                break;
            }
        }

        this.setState({
            hours: zero(hours),
            minutes: zero(minutes),
            seconds: zero(seconds),
            date: `${weekDayName}, ${monthName} ${day}${cardinal}, ${year}`,
            greeting,
            greetingSubject: 'MenendezLechugas',
            ampm: hours >= 12 ? 'p.m.' : 'a.m.',
            status: `${window.innerWidth} x ${window.innerHeight}`
        })
    }

    componentDidMount() {

        this.update();
        this.intervalSubscription = setInterval(() => {
            this.update();
        }, 500)
    }

    componentWillUnmount() {
        clearInterval(this.intervalSubscription);
    }

    render() {
        return (
            <div className="cover-view">
                <div className="wrap">
                    <div className="salutation">
                        <div className="greeting">{this.state.greeting}</div>
                        <div className="family">{this.state.greetingSubject}</div>
                    </div>
                </div>
                <div className="watch">
                    <div className="time">
                        <span className="hours">{this.state.hours}</span><span className="colon">:</span><span
                        className="minutes">{this.state.minutes}</span>:<span className="seconds">{this.state.seconds}</span>
                        <span className="ampm">{this.state.ampm}</span>
                    </div>
                    <div className="date">{this.state.date}</div>
                </div>
                <div className="status">{this.state.status}</div>
                <footer>Menendez Lechuga Family</footer>
            </div>
        );
    }

}
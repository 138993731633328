import {GoalRepo, GoalSet} from "./Goal";
import {BioMetricMeasure, BioMetricRepo} from "./BioMetric";
import {User, UserRepo} from "./User";


export interface GoalCacheEntry {
    user: User;
    goalSet: GoalSet;
    measures: BioMetricMeasure[];
}

export class GoalsCache{

    private data: GoalCacheEntry[];
    private loading = false;

    async load(){

        this.loading = true;

        const newData: GoalCacheEntry[] = [];
        const users = await UserRepo.getAll();

        for(const user of users){

            const goalSets = await GoalRepo.byUser(user.uname);

            if (goalSets.length > 0){
                const goalSet = goalSets[0];
                const measures = await BioMetricRepo.byUser(user.uname, goalSet.startDate, goalSet.endDate);

                newData.push({user, goalSet, measures});
            }

        }

        this.data = newData;
        this.loading = false;

    }

    async invalidate(){
        this.data = [];

        await this.load();
    }

    byUser(uname: string): GoalCacheEntry | undefined{
        return this.data.find(c => c.user.uname === uname);
    }

    get isLoading(): boolean{
        return this.loading;
    }

}